import Image from 'next/image';
import { ButtonLink, faBook, faCheckCircle, faCircleQuestion, Icon } from 'packages/elements';

import logo from '@/assets/logo.svg';
import logoWhite from '@/assets/logo-white.svg';
import { external } from '@/routes';

import { NextPageWithConfig } from './_app.page';

export const FourOhFour = () => {
  return (
    <div className="bg-neutral-shades-50 dark:bg-neutral-shades-950 min-h-screen p-8 flex flex-col justify-between text-neutral-1000 dark:text-neutral-0">
      <div className="h-8">
        <Image src={logo} alt="Upbound" height="21" className="dark:hidden" />
        <Image src={logoWhite} alt="Upbound" height="21" className="hidden dark:block" />
      </div>
      <div className="flex flex-col justify-center items-center">
        <h1 className="font-sans font-extrabold text-4xl pb-2">Error 404</h1>
        <p className="mb-8 text-center">
          Sorry about that, it looks like the page you are trying to reach doesn’t exist.
        </p>
        <p className="max-w-sm w-full text-center font-normal text-sm mb-4">
          Please contact Upbound Support or join our Slack channel if you think this is in error.
        </p>
        <ButtonLink className="max-w-sm w-full mb-3" href={external.contactUs.url()}>
          Contact Upbound Support
        </ButtonLink>
        <ButtonLink btnType="Secondary" className="max-w-sm w-full" href={external.crossplaneSlack.url()}>
          Join the Crossplane Slack Channel
        </ButtonLink>
        <h3 className="text-neutral-600 dark:text-neutral-400 text-sm font-normal mt-6">Other helpful links</h3>
        <div className="flex text-purple-600 dark:text-purple-400 text-sm font-normal mt-2 gap-2.5 flex-col sm:flex-row">
          <a href={external.upboundStatus.url()} className="hover:underline">
            <Icon icon={faCheckCircle} className="mr-1" />
            Upbound System Status
          </a>
          <a href={external.docsHome.url()} className="hover:underline">
            <Icon icon={faBook} className="mr-1" />
            Upbound Documentation
          </a>
          <a href={external.marketingFaq.url()} className="hover:underline">
            <Icon icon={faCircleQuestion} className="mr-1" />
            Upbound FAQ’s
          </a>
        </div>
      </div>
      <div className="max-h-8 grow"></div>
    </div>
  );
};

const FourOhFourPage: NextPageWithConfig = () => {
  return <FourOhFour />;
};

FourOhFourPage.config = { reRoute: 'error', layout: false };

export default FourOhFourPage;
