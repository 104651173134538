import { NextPageWithConfig } from './_app.page';
import FourOhFour from './404.page';

// Note: The only time this page will render is for root of dev portal because
//       the selectOrg redirect will take precedence when not dev portal.
const Root: NextPageWithConfig = () => {
  return <FourOhFour />;
};

Root.config = { reRoute: 'root', layout: false };

export default Root;
